import { ProductCard } from '@components/product'
import SignatureStylesSliderControl from '@components/slices/SignatureStylesSlider/SignatureStylesSliderControl'
import { getConfigByLocale } from '@lib/locale'
import { PrismicImage } from '@lib/prismic'
import cn from 'classnames'
import { useKeenSlider } from 'keen-slider/react'
import { useRouter } from 'next/router'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import React, { FC, useState } from 'react'

interface props {
  primary: {
    heading: string
    description?: RichTextBlock[]
    bg_color?: string
    padding_top: boolean
  }
  items: {
    title: string
    range: string
    price: string
    compare_price: string
    handle: string
    icon: PrismicImage
    image: PrismicImage
    tags: string
    contents: RichTextBlock[]
    background_colour?: string
    video: {
      url: string | any
    }
  }[]
}

const FeaturedProducts: FC<props> = ({ primary, items }) => {
  const { locale } = useRouter()
  const [currentSlide, setCurrentSlide] = useState(0)
  const currencyCode = getConfigByLocale(locale, 'defaultCurrencyCode')
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: 3,
    spacing: 16,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })
  const [currentMobileSlide, setCurrentMobileSlide] = useState(0)
  const [sliderRefMobile, sliderMobile] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: 1.142,
    spacing: 12,
    slideChanged(s) {
      setCurrentMobileSlide(s.details().relativeSlide)
    },
    loop: true,
  })

  const onPrev = React.useCallback(() => slider.prev(), [slider])
  const onNext = React.useCallback(() => slider.next(), [slider])

  return (
    <section style={{ backgroundColor: primary.bg_color }}>
      <div
        className={cn('wrapper pb-48 laptop:pb-80', {
          'pt-40 laptop:pt-72': primary?.padding_top === true,
        })}
      >
        <div className=" overflow-hidden laptop:-mr-32 laptop:pr-32">
          {primary.heading && <h2 className="heading-5 text-black">{primary.heading}</h2>}
          {primary?.description && <div className="body mt-8">{RichText.render(primary.description)}</div>}
          <div className="mt-24 laptop:mt-32">
            <div className="h-0 laptop:h-auto sliderContainer">
              <div ref={sliderRef} className="keen-slider h-full ">
                {items &&
                  items?.map((item, index) => {
                    const handleClick = () => {
                      // possible gtm event
                    }
                    return (
                      <div className="keen-slider__slide" key={index}>
                        {item.image?.url ? (
                          <ProductCard
                            title={item.title}
                            image={item.image?.url}
                            handle={item.handle}
                            price={Number(item.price)}
                            comparePrice={Number(item.compare_price)}
                            tags={item.tags?.split(',')}
                            range={item.range}
                            handleClick={handleClick}
                            currencyCode={currencyCode}
                            video={item.video}
                          />
                        ) : (
                          <>
                            {item?.contents && (
                              <div
                                className=" p-40  tablet:text-12 tablet:leading-20 text-charcoal w-full flex items-center justify-center contentsContainer laptop:heading-5"
                                style={{ backgroundColor: item.background_colour }}
                              >
                                <RichText render={item?.contents} />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )
                  })}
                {slider && (
                  <div className="opacity-0 sliderControl">
                    <SignatureStylesSliderControl onPrev={onPrev} onNext={onNext} />
                  </div>
                )}
              </div>
            </div>

            <div className="block h-auto opacity-100 laptop:h-0 laptop:opacity-0 -mr-20">
              <div ref={sliderRefMobile} className="keen-slider">
                {items &&
                  items?.map((item, index) => {
                    const handleClick = () => {
                      // possible gtm event
                    }
                    return (
                      <div key={index} className={cn('keen-slider__slide', {})}>
                        {item.image?.url ? (
                          <ProductCard
                            title={item.title}
                            image={item.image?.url}
                            handle={item.handle}
                            price={Number(item.price)}
                            comparePrice={Number(item.compare_price)}
                            tags={item.tags?.split(',')}
                            range={item.range}
                            handleClick={handleClick}
                            mobileSize={true}
                            currencyCode={currencyCode}
                            video={item.video}
                          />
                        ) : (
                          <>
                            {item?.contents && (
                              <div
                                className={cn(
                                  'contentsMobileContainer p-40 heading-5 text-charcoal flex items-center justify-center contentsContainer'
                                )}
                                style={{ backgroundColor: item.background_colour }}
                              >
                                <RichText render={item?.contents} />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
            {sliderMobile && (
              <div className="flex justify-center mt-28 bg-lightGrey laptop:hidden">
                {[...Array(sliderMobile.details().size).keys()].map((idx) => {
                  return (
                    <div
                      key={idx}
                      className={
                        'w-11/12 h-4 rounded-5 focus:outline-none' + (currentMobileSlide === idx ? ' bg-black' : '')
                      }
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .sliderControl {
            transition: all 0.5s ease;
          }
          .sliderContainer:hover .sliderControl {
            opacity: 1;
          }
        `}
      </style>
    </section>
  )
}

export default FeaturedProducts
